export const paletteMui = {
    primary: {
        main: '#6FDC99',
        contrastText: '#fff'
    },
    secondary: {
        main: '#B0B0B0',
        contrastText: '#fff'
    }
};

export const paletteCustomize = {
    ...paletteMui,
    imgOutline: '#71706E'
};
