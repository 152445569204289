import { Box } from '@mui/material';

import { ItemTag } from '@components/_index';
import '@template/components/ItemFieldPart/ItemTagGroupMerged/ItemTagGroupMerged.scss';

import '@styles/common/ItemFieldPart/ItemTagGroupMerged.scss';

export default function ItemTagGroupMerged({ tagNames }) {
    return (
        <Box className="itemTagGroupMerged itemTags">
            {tagNames.map((tagName) => {
                return <ItemTag key={tagName} tagName={tagName} />;
            })}
        </Box>
    );
}
