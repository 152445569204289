import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

// import { ReactComponent as IconTick } from '@images/iconGeneral/tick.svg';
import './StepperVertical.scss';

export default function StepperVertical({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);
    const isAllCompleted = currStepIndex === camelStepNames.length - 1;

    return (
        <Box className="stepper stepperVertical">
            {/* <Box className="logoBrandWrapper">
                <LogoBrand />
            </Box> */}
            <Box className="stepWrapper">
                {camelStepNames.map((camelStepName, index) => {
                    const isCompletedStep = index < currStepIndex;
                    const isCurrIndex = index === currStepIndex;
                    const isLastStep = index === camelStepNames.length - 1;
                    return (
                        <Box
                            key={camelStepName}
                            className={cx([
                                'step',
                                { currStep: isCurrIndex },
                                {
                                    completedStep:
                                        isCompletedStep || isAllCompleted
                                }
                            ])}>
                            <Box className={'stepItm'} key={camelStepName}>
                                <Box className="stepName">
                                    {t('common.step.' + camelStepName)}
                                </Box>
                                <Box className="stepIndex">{index + 1}</Box>
                            </Box>
                            {!isLastStep && (
                                <Divider
                                    className="stepVerticalLine"
                                    orientation="vertical"
                                />
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
