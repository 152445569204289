import { FLEX_LAYOUT } from '@constants/styleLayout';

export const initialSettingState = {
    isShowBuyAddressInfosCountField: false,
    itemFieldLeftFlexLayout: FLEX_LAYOUT.SPACE_BETWEEN,
    // loadingType: LOADING_TYPE.FULL_PAGE,
    isPayMethodUnderTraderName: false, // put pay method under trader name or before payment bank/alipay
    isBtnGroupAsideMainComponent: true, // layout grouping in pay process account number
    isShowBuySellPrices: false // display buy sell prices or just one price
};
